//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    topLineLeft: {
      type: String,
      default: null
    },
    topLineRight: {
      type: String,
      default: null
    },
    logoLeft: {
      type: Object,
      default: null
    },
    logoRight: {
      type: Object,
      default: null
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const showTopLine = computed(() => {
      return props.topLineLeft || props.topLineRight
    })

    const route = useRoute()

    const excludedPaths = ['/', '/ueber-uns/', '/partner-werden/', '/unsere-partner/', '/finde-deine-werkstatt/', '/error/404/', '/error/500/']

    const showPartnered = computed(() => {
      return !excludedPaths.includes(route.value.path)
    })

    return {
      showTopLine,
      showPartnered
    }
  }
})
